import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';

// Firebase config (replace with your own)
const firebaseConfig = {
  apiKey: "AIzaSyBtjurfRPKVgdfWmv922JF7BVg3sLkRuuw",
  authDomain: "sabine-92d34.firebaseapp.com",
  projectId: "sabine-92d34",
  storageBucket: "sabine-92d34.firebasestorage.app",
  messagingSenderId: "268954616425",
  appId: "1:268954616425:web:2da8c0a9bcedd8e33158f8",
  measurementId: "G-YQYP0DL807"
};

const vapidKey = "BD7AHxoaLKaktuOF8r-2r51YwqLvtyXhcGXIThYk5ukHPhfsTE2t8gDK-D1CBuS-OaDIU5CMVQpzIfUbtcRnUpc"


// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Get messaging instance
const messaging = getMessaging(app);



export { messaging, getToken };

// export const requestFCMToken = async () => {
//   return Notification.requestPermission()
//     .then((permission) => {
//       if (permission === "granted") {
//         return getToken(messaging, { vapidKey })
//       } else {
//         throw new Error("Noficiation not granted")
//       }
//     })
//     .catch((err) => {
//       console.error("Error getting FCM token: ", err)
//       throw err;
//     })
// }

export const requestFCMToken = async () => {
  try {
    // Request notification permission
    const permission = await Notification.requestPermission();

    if (permission !== "granted") {
      throw new Error("Notification permission not granted.");
      
    }

    // Get the FCM token
    const token = await getToken(messaging, { vapidKey });

    if (token) {
      console.log("FCM Token:", token);
      return token;
    } else {
      console.warn("No FCM token available.");
      throw new Error("Failed to retrieve FCM token.");
    }
  } catch (error) {
    console.error("Error getting FCM token: ", error);
    throw error; // Re-throw the error for handling in the calling function
  }
};
