import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  AUTH_ID,
  AUTH_NAME,
  AUTH_ROLES,
  AUTH_TOKEN,
  AUTH_TYPE,
  AUTH_USER,
} from "constants/AuthConstant";
import AuthService from "services/AuthService";
import { getToken } from "firebase/messaging";
import { messaging, requestFCMToken } from "auth/firebaseConfig";
import { VAPID_KEY } from "configs/AppConfig";

export const initialState = {
  loading: false,
  message: "",
  showMessage: false,
  redirect: "",
  token: localStorage.getItem(AUTH_TOKEN) || null,
  user: JSON.parse(localStorage.getItem(AUTH_USER)) || null,
  userId: localStorage.getItem(AUTH_ID) || null,
  type: localStorage.getItem(AUTH_TYPE) || null,
  userName: localStorage.getItem(AUTH_NAME) || null,
  roles: JSON.parse(localStorage.getItem(AUTH_ROLES)) || null,
  constants: null,
};
// while 'signIn' we store required data to localStorage and loads to initialState of redux
// when the page is reloaded

export const tokenGenerator = async () => {
  //fcm token

  try {
    console.log("FUNCTION TO CALL");
    const currentToken = await requestFCMToken();
    if (currentToken) {
      console.log("token final generated", currentToken);
      return currentToken;
    }
  } catch (error) {
    console.error("Error getting FCM token:", error);
  }
};

export const signIn = createAsyncThunk(
  "auth/signIn",
  async (data, { rejectWithValue }) => {
    const { authinfo, password } = data;

    try {
      const currentToken = await tokenGenerator();
      const storedtoken = localStorage.getItem("devicetoken");
      // const currentToken = localStorage.getItem('devicetoken');

      const response = await AuthService.login({
        authinfo,
        password,
        deviceToken: currentToken
          ? currentToken
          : storedtoken
          ? storedtoken
          : null,
      });

      // const { sessionToken: token, type, roles, id, user } = response.data;
      const {
        sessionToken: token,
        user: { type, permissions = [], _id: id, username },
      } = response.data;
      localStorage.setItem(AUTH_TOKEN, token);
      localStorage.setItem(AUTH_TYPE, response.data.user.type);
      localStorage.setItem(AUTH_ID, response.data.user._id);
      localStorage.setItem(AUTH_NAME, response.data.user.name);
      localStorage.setItem(AUTH_USER, JSON.stringify(response.data.user));

      let mappedRoles = {};
      if (permissions) {
        mappedRoles = {};
        permissions.forEach(({ key, ...role }) => {
          mappedRoles[key] = role;
        });
      }
      localStorage.setItem(AUTH_ROLES, JSON.stringify(mappedRoles));
      return {
        token,
        type,
        roles: mappedRoles,
        id,
        username,
        user: response.data.user,
      };

      //-------for firebase login -----------
      // const response = await FirebaseService.signInEmailRequest(email, password)
      // if (response.user) {
      // 	const token = response.user.refreshToken;
      // 	localStorage.setItem(AUTH_TOKEN, response.user.refreshToken);
      // 	return token;
      // } else {
      // 	return rejectWithValue(response.message?.replace('Firebase: ', ''));
      // }
      //-------------------------------------
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const signUp = createAsyncThunk(
  "auth/signUp",
  async (data, { rejectWithValue }) => {
    const { email, password } = data;
    try {
      // const response = await FirebaseService.signUpEmailRequest(email, password)
      // if (response.user) {
      // 	const token = response.user.refreshToken;
      // 	localStorage.setItem(AUTH_TOKEN, response.user.refreshToken);
      // 	return token;
      // } else {
      // 	return rejectWithValue(response.message?.replace('Firebase: ', ''));
      // }
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const signOut = createAsyncThunk("auth/signOut", async () => {
  // const response = await FirebaseService.signOutRequest() //for firebase
  localStorage.removeItem(AUTH_TOKEN);
  localStorage.removeItem(AUTH_TYPE);
  localStorage.removeItem(AUTH_ROLES);
  localStorage.removeItem(AUTH_ID);
  localStorage.removeItem(AUTH_NAME);
  localStorage.removeItem(AUTH_USER);
  // return response.data //for firebase
});

export const signInWithGoogle = createAsyncThunk(
  "auth/signInWithGoogle",
  async (_, { rejectWithValue }) => {
    // const response = await FirebaseService.signInGoogleRequest()
    // if (response.user) {
    // 	const token = response.user.refreshToken;
    // 	localStorage.setItem(AUTH_TOKEN, response.user.refreshToken);
    // 	return token;
    // } else {
    // 	return rejectWithValue(response.message?.replace('Firebase: ', ''));
    // }
  }
);

export const signInWithFacebook = createAsyncThunk(
  "auth/signInWithFacebook",
  async (_, { rejectWithValue }) => {
    // const response = await FirebaseService.signInFacebookRequest()
    // if (response.user) {
    // 	const token = response.user.refreshToken;
    // 	localStorage.setItem(AUTH_TOKEN, response.user.refreshToken);
    // 	return token;
    // } else {
    // 	return rejectWithValue(response.message?.replace('Firebase: ', ''));
    // }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    authenticated: (state, action) => {
      state.loading = false;
      state.redirect = "/";
      // state.token = action.payload
      state.token = action.payload.token;
      state.userId = action.payload.id;
      state.type = action.payload.type;
      state.user = action.payload.user;
      state.userName = action.payload.username;
      state.roles = action.payload.roles;
    },
    showAuthMessage: (state, action) => {
      state.message = action.payload;
      state.showMessage = true;
      state.loading = false;
    },
    hideAuthMessage: (state) => {
      state.message = "";
      state.showMessage = false;
    },
    signOutSuccess: (state) => {
      state.loading = false;
      state.token = null;
      state.redirect = "/";
    },
    showLoading: (state) => {
      state.loading = true;
    },
    signInSuccess: (state, action) => {
      state.loading = false;
      state.token = action.payload;
    },
    updateProfile: (state, action) => {
      state.user = action.payload.user;
      state.userName = action.payload.user.name;
    },
    updateConstants: (state, action) => {
      state.constants = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signIn.pending, (state) => {
        state.loading = true;
      })
      .addCase(signIn.fulfilled, (state, action) => {
        state.loading = false;
        state.redirect = "/";
        // state.token = action.payload
        state.token = action.payload.token;
        state.userId = action.payload.id;
        state.type = action.payload.type;
        state.user = action.payload.user;
        state.userName = action.payload.username;
        state.roles = action.payload.roles;
      })
      .addCase(signIn.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(signOut.fulfilled, (state) => {
        state.loading = false;
        state.token = null;
        state.redirect = "/";
        state.type = null;
        state.userName = null;
        state.user = null;
        state.userId = null;
        state.roles = {};
      })
      .addCase(signOut.rejected, (state) => {
        state.loading = false;
        state.token = null;
        state.redirect = "/";
      })
      .addCase(signUp.pending, (state) => {
        state.loading = true;
      })
      .addCase(signUp.fulfilled, (state, action) => {
        state.loading = false;
        state.redirect = "/";
        state.token = action.payload;
      })
      .addCase(signUp.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(signInWithGoogle.pending, (state) => {
        state.loading = true;
      })
      .addCase(signInWithGoogle.fulfilled, (state, action) => {
        state.loading = false;
        state.redirect = "/";
        state.token = action.payload;
      })
      .addCase(signInWithGoogle.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(signInWithFacebook.pending, (state) => {
        state.loading = true;
      })
      .addCase(signInWithFacebook.fulfilled, (state, action) => {
        state.loading = false;
        state.redirect = "/";
        state.token = action.payload;
      })
      .addCase(signInWithFacebook.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      });
  },
});

export const {
  authenticated,
  showAuthMessage,
  hideAuthMessage,
  signOutSuccess,
  showLoading,
  signInSuccess,
  updateProfile,
  updateConstants,
} = authSlice.actions;

export default authSlice.reducer;
