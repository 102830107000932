import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom'
import { ThemeSwitcherProvider } from 'react-css-theme-switcher';
import store from './store';
import history from './history'
import Layouts from './layouts'
import { THEME_CONFIG } from './configs/AppConfig';
import './lang'
import { messaging } from 'auth/firebaseConfig';
import { getMessaging, onMessage } from "firebase/messaging";
import { notification } from 'antd';
import { BellOutlined } from "@ant-design/icons";
import { tokenGenerator } from 'store/slices/authSlice';


const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

function App() {

  const generateToken = async() => {

    const permission = await Notification.requestPermission();

    if (permission !== "granted") {
      throw new Error("Notification permission not granted.");
      
    }

    const currentToken = await tokenGenerator();
    if(currentToken){
      localStorage.setItem('devicetoken',currentToken)
      console.log('token genrated in app', currentToken)
    }
    }

    useEffect(()=>{ 
      generateToken();
    },[])

  // useEffect(()=>{
  //   if ('serviceWorker' in navigator) {
  //     navigator.serviceWorker.register('/firebase-messaging-sw.js')
  //       .then((registration) => {
  //         messaging.useServiceWorker(registration);  // Link to Firebase Messaging
  //         console.log("Service Worker registered successfully");
  //       })
  //       .catch((error) => {
  //         console.error("Service Worker registration failed:", error);
  //       });
  //   }
  // },[])

 

useEffect(() => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/firebase-messaging-sw.js')
      .then((registration) => {
        const messaging = getMessaging();
        // Normally, you would now listen for notifications like this:
        onMessage(messaging, (payload) => {
          console.log("Message received. ", payload);
          notification.open({
            message: payload.notification.title,
            description:
              payload.notification.body,
            icon: <BellOutlined style={{ color: "#108ee9" }} />, // Custom icon
          });
        });

        console.log("Service Worker registered successfully");
      })
      .catch((error) => {
        console.error("Service Worker registration failed:", error);
      });
  }
}, []);


  // Register the service worker


  const messaging = getMessaging();

  useEffect(() => {
    // Listen for messages when the app is in the foreground
    onMessage(messaging, (payload) => {
      console.log("Message received. ", payload);
      notification.open({
        message: payload.notification.title,
        description:
          payload.notification.body,
        icon: <BellOutlined style={{ color: "#108ee9" }} />, // Custom icon
      });
    });
  }, []);


  return (
    <div className="App">
      <Provider store={store}>
        <BrowserRouter history={history}>
          <ThemeSwitcherProvider
            themeMap={themes}
            defaultTheme={THEME_CONFIG.currentTheme}
            insertionPoint="styles-insertion-point"
          >
            <Layouts />
          </ThemeSwitcherProvider>
        </BrowserRouter>
      </Provider>
    </div>
  );
}

export default App;
