export const subadminsKey = 'subadmins';
export const subadminRolesKey = 'subadminRoles';
export const informationPolicyKey = 'informationPolicy';
export const categoriesKey = 'categories';
export const contactUsKey = 'contactUs';
export const testimonialKey = 'testimonial';
export const galleryKey = 'gallery';
export const servicesKey = 'services';
export const branchesKey = 'branches';
export const brandsKey = 'brands';
export const pagesKey = 'Pages';
export const bannerKey = 'banners';
export const widgetsKey = 'widgets';
export const adminSettingsKey = 'adminSettings';
export const clientsKey = 'clients';
export const seoKey = 'seo';
export const departmentsKey = 'departments';
export const doctorsKey = 'doctors';
export const tagManagerKey = 'tagManager';
