import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE, DIR_LTR } from 'constants/ThemeConstant';
import { env } from './EnvironmentConfig'

export const APP_NAME = 'Sabine Hospitals';
export const VAPID_KEY = 'BD7AHxoaLKaktuOF8r-2r51YwqLvtyXhcGXIThYk5ukHPhfsTE2t8gDK-D1CBuS-OaDIU5CMVQpzIfUbtcRnUpc';
export const API_BASE_URL = env.API_ENDPOINT_URL
export const APP_PREFIX_PATH = '/app';
export const AUTH_PREFIX_PATH = '/auth';
export const REDIRECT_URL_KEY = 'redirect'
export const AUTHENTICATED_ENTRY = `${APP_PREFIX_PATH}/dashboards/default`; //default page in dashboard
export const UNAUTHENTICATED_ENTRY = '/login'
export const REDIRECT_COMPONENT_KEY = 'dashboard';

export const THEME_CONFIG = {
	navCollapsed: false,
	sideNavTheme: SIDE_NAV_LIGHT,
	locale: 'en',
	navType: NAV_TYPE_SIDE,
	topNavColor: '#3e82f7',
	headerNavColor: '',
	mobileNav: false,
	currentTheme: 'light',
	direction: DIR_LTR,
	blankLayout: false
};
// in emilus template > setting icon in navbar > select parameters like 'Header Nav Color', 'Dark Theme',
// 'Side Nav Color', 'Navigation Type' etc > click 'Copy Setting' and paste the value in 'THEME_CONFIG'.
